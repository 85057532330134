import React, { ElementRef } from 'react'
import { Body } from '@sc/components/Typography'
import { styled, StyledProps } from '@sc/theme/stitches.config'

export const StyledButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',

  height: '$controlHeight',
  paddingLeft: '$paddingUIXL',
  paddingRight: '$paddingUIXL',
  fontSize: '$base',
  lineHeight: '$normal',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$paddingBetweenSmall',

  // All buttons have a border so that nothing changes when
  // it's added/removed for different buttons or on hover
  borderWidth: '$control',
  borderColor: 'transparent',
  borderStyle: 'solid',
  borderRadius: '$L',

  cursor: 'pointer',
  whiteSpace: 'nowrap',
  color: '$fgHigh',

  '&:focus-visible': { boxShadow: '$focus', outline: 'none' },

  '&:disabled': { cursor: 'not-allowed' },

  '&:has(> div:not(:last-child))': {
    paddingLeft: '$paddingUIM'
  },

  '&:has(> div:last-child)': {
    paddingRight: '$paddingUIM'
  },

  variants: {
    variant: {
      primary: {
        backgroundColor: '$actionPrimary'
      },
      secondary: {
        backgroundColor: '$actionSecondary'
      },
      tertiary: {
        borderColor: '$actionOutline',
        backgroundColor: 'transparent',
        color: '$text',

        '&[data-state="open"]': {
          backgroundColor: '$actionSecondary',
          borderColor: 'transparent'
        },

        '&:active': {
          backgroundColor: '$actionSecondary'
        }
      },
      ghost: {
        backgroundColor: 'transparent',
        color: '$text',
        '&:hover:not(:disabled)': {
          backgroundColor: '$actionSecondary'
        }
      },
      danger: {
        backgroundColor: '$surfaceDangerHigh',
        '& > span': {
          color: '$fgHighDanger'
        }
      }
    },

    // Used disabledVariant to avoid overriding
    // the native HTML property
    disabledVariant: {
      true: {
        backgroundColor: '$actionDisabled',
        '& > span': {
          color: '$fgMed'
        }
      }
    },

    /**
     * Only applicable to `tertiary` variant
     */
    active: {
      true: {}
    }
  },

  compoundVariants: [
    {
      active: true,
      variant: 'tertiary',
      css: {
        borderColor: '$highContrast'
      }
    },
    {
      variant: 'danger',
      disabledVariant: true,
      css: {
        backgroundColor: '$surfaceDangerLow',
        '& > span': {
          color: '$fgMedDanger'
        }
      }
    },
    {
      variant: 'tertiary',
      disabledVariant: true,
      css: {
        backgroundColor: 'transparent'
      }
    },
    {
      variant: 'ghost',
      disabledVariant: true,
      css: {
        backgroundColor: 'transparent'
      }
    }
  ],

  defaultVariants: {
    variant: 'primary'
  }
})

type ButtonRef = ElementRef<typeof StyledButton>
type StyledButtonProps = StyledProps<typeof StyledButton>
export type ButtonProps = StyledButtonProps & {
  /**
   * When a Button is `disabled`, it's not possible to use it or click it.
   */
  disabled?: StyledButtonProps['disabled']
}

// eslint-disable-next-line prefer-arrow-callback
export const Button = React.forwardRef<ButtonRef, ButtonProps>(function _Button(
  { children, disabled, ...props },
  forwardedRef
) {
  const formattedChildren = React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      // We need this because the CSS selectors don't know what to do
      // If the text hasn't got container
      return <Body as="span">{child}</Body>
    }

    return child
  })

  return (
    <StyledButton
      disabled={disabled}
      disabledVariant={disabled}
      ref={forwardedRef}
      type="button"
      {...props}
    >
      {formattedChildren}
    </StyledButton>
  )
})

export const IconButton = React.forwardRef<ButtonRef, ButtonProps>(
  ({ css, ...props }, forwardedRef) => (
    <Button
      css={{
        flexShrink: 0,
        width: '$controlHeight',
        padding: '0 !important',
        ...css
      }}
      ref={forwardedRef}
      {...props}
    />
  )
)

type NavButtonProps = Omit<ButtonProps, 'variant'> & {
  active?: boolean
}

export const NavButton = React.forwardRef<ButtonRef, NavButtonProps>(
  ({ active, ...props }, forwardedRef) => (
    <Button
      ref={forwardedRef}
      variant={active ? 'primary' : 'ghost'}
      {...props}
    />
  )
)

/**
 * This button is inteded to be used on a radix context. The "active" state
 * is controlled by [data-state] attributes that Radix automatically sets.
 */
export const RadixTabButton = React.forwardRef<
  ButtonRef,
  Omit<ButtonProps, 'variant'>
>(({ css, ...props }, ref) => (
  <Button
    ref={ref}
    css={{
      backgroundColor: 'transparent',
      width: '100%',

      // To use with radix tab trigger
      '&[data-state="active"]': {
        backgroundColor: '$actionPrimary'
      },

      // To use with radix radiogroup item
      '&[data-state="checked"]': {
        backgroundColor: '$actionPrimary'
      },

      ...css
    }}
    {...props}
  />
))
